import api from '@iso/containers/services/API/api';
import { message } from 'antd';
import dayjs from 'dayjs';
import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ApiBuild from '../containers/services/API/apiBuild';

export const contextImportsXmlObj = createContext({});

const ImportXmlFNContext = ({ children }) => {
  // const api = ApiBuild();

  const [fileConverted, setFileConverted] = useState([]);
  const [dataImport, setDataImport] = useState([]);
  const [isSendingImport, setIsSendingImport] = useState(false);
  const [isNewFile, setIsNewFile] = useState(false);
  const [isGasFileGenerate, setIsGasFileGenerate] = useState(false);
  const [isOilFileGenerate, setIsOilFileGenerate] = useState(false);
  const [base64Error, setBase64Error] = useState('');
  const [isErrorFile, setIsFileError] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [fileData, setFileData] = useState([]);
  const [summary, setSummary] = useState([]);
  const [tableSummary, setTableSummary] = useState([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const isValidFile = async (file) => {
    // const api = ApiBuild();
    try {
      const objFile = {
        fileType: file.name.slice(0, 3),
        fileName: file.name,
        contentBase64: `data:@file/xml;base64,${file.base64}`,
      };

      // const res = await api.post(
      //   `${process.env.REACT_APP_API2_URL}/import-nfsm/validate`,
      //   { file: objFile },
      // );
      const res = await api.post(`/import-nfsm/validate`, { file: objFile });

      setFileData(res.data);
      setSummary(res.data.nfsMs[0]);
      setIsFileError(false);

      return {
        status: true,
      };
    } catch (error) {
      return {
        status: false,
        fileName: file.name,
        errorMessage: error.data.errors,
      };
    }
  };

  const getDataImport = async (filesFormated, type) => {
    try {
      // const res = await api.post(
      //   `${process.env.REACT_APP_API2_URL}/import-nfsm/validate`,
      //   { file: filesFormated },
      // );

      setLoading(true);

      const res = await api.post(`/import-nfsm/validate`, {
        file: filesFormated,
      });

      return res.data;
    } catch (error) {
      setIsFileError(true);

      // const res = await api.post(
      //   `${process.env.REACT_APP_API2_URL}/import-nfsm/errors`,
      //   { errors: error.data.errors },
      // );
      const res = await api.post(`/import-nfsm/errors`, {
        errors: error.data.errors,
      });
      console.log(error.data);

      setBase64Error(res.data.contentBase64);
    } finally {
      setLoading(false);
    }
  };

  const dataTableArr = async (filesArr, fileType) => {
    const arrFilesFiltered = filesArr.filter((file) => file.status === 'done');

    const arr = arrFilesFiltered.map((file) => {
      return {
        fileType: file.name.slice(0, 3),
        fileName: file.name,
        contentBase64: `data:@file/xml;base64,${file.base64}`,
      };
    });

    try {
      setIsSendingImport(true);

      const res = await getDataImport(arr[0], fileType);

      return res;
    } catch (error) {
      console.log(error);
    } finally {
      setIsSendingImport(false);
    }
  };

  const GetAllFailureNotification = async (
    isBotafogoInstance,
    selectedInstance,
  ) => {
    try {
      setLoading(true);

      await api
        .get(
          isBotafogoInstance ? `nfsms?instance=${selectedInstance}` : `nfsms`,
        )
        .then((response) => {
          const { data } = response;

          const dataWithKey = data.map((d) => {
            return {
              ...d,
              key: d.id,
              file: {
                ...d.file,
                importedAt: dayjs(d.file.importedAt).format('DD/MMM/YYYY'),
              },
            };
          });

          setTableData(dataWithKey);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const correctionApplication = async (idProduction, history) => {
    try {
      setLoading(true);
      // const { data } = await api.patch(
      //   `${process.env.REACT_APP_API2_URL}/nfsms/${idProduction}/apply`,
      // );

      await api.patch(`/nfsms/${idProduction}/apply`);

      message.success('Correção Aplicada', 3);
      history();
    } catch (error) {
      message.error(error.data.message, 5);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <contextImportsXmlObj.Provider
      value={{
        dataTableArr,
        fileConverted,
        isSendingImport,
        dataImport,
        getDataImport,
        // dataTableArrGas,
        setDataImport,
        setFileConverted,
        isNewFile,
        setIsNewFile,
        base64Error,
        isErrorFile,
        isValidFile,
        isGasFileGenerate,
        setIsGasFileGenerate,
        isOilFileGenerate,
        setIsOilFileGenerate,
        summary,
        setSummary,
        fileData,
        setFileData,
        tableSummary,
        setTableSummary,
        correctionApplication,
        GetAllFailureNotification,
        tableData,
        loading,
        setLoading,
      }}
    >
      {children}
    </contextImportsXmlObj.Provider>
  );
};

export default ImportXmlFNContext;
