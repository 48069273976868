import { createContext, useState } from 'react';
import React from 'react';

export const createContextCadastroBotafogo = createContext({});

const BotafogoCadastrosContext = ({ children }) => {
  const [treeSelectDataBotafogo, setTreeSelectDataBotafogo] = useState([]);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [statusChanged, setStatusChanged] = useState();

  const treeData = [
    {
      title: 'Administração',
      value: '0-0',
      key: '0-0',
      children: [
        {
          title: 'Cadastros Básicos',
          value: '0-0-0',
          key: '0-0-0',
          children: [
            {
              title: 'Cadastros Básicos - Visualização',
              value: '0-0-0-0',
              key: '0-0-0-0',
            },
          ],
        },
        {
          title: 'Grupos',
          value: '0-0-1',
          key: '0-0-1',
          children: [
            {
              title: 'Grupos - Visualização',
              value: '0-0-1-0',
              key: '0-0-1-0',
            },
            {
              title: 'Grupos - Atualização',
              value: '0-0-1-1',
              key: '0-0-1-1',
            },
            {
              title: 'Grupos - Criação',
              value: '0-0-1-2',
              key: '0-0-1-2',
            },
          ],
        },
        {
          title: 'Usuários',
          value: '0-0-2',
          key: '0-0-2',
          children: [
            {
              title: 'Usuários - Visualização',
              value: '0-0-2-0',
              key: '0-0-2-0',
            },
            {
              title: 'Usuários - Atualização',
              value: '0-0-2-1',
              key: '0-0-2-1',
            },
            {
              title: 'Usuários - Criação',
              value: '0-0-2-2',
              key: '0-0-2-2',
            },
          ],
        },
        {
          title: 'Configurar Cálculos',
          value: '0-0-3',
          key: '0-0-3',
          children: [
            {
              title: 'Configurar Cálculos - Visualização',
              value: '0-0-3-0',
              key: '0-0-3-0',
            },
          ],
        },
        {
          title: 'Importar Dados de Hierarquia',
          value: '0-0-4',
          key: '0-0-4',
          children: [
            {
              title: 'Importar Dados de Hierarquia - Visualização',
              value: '0-0-4-0',
              key: '0-0-4-0',
            },
          ],
        },
      ],
    },
    {
      title: 'Gestão de Dados de Produção',
      value: '0-1',
      key: '0-1',
      children: [
        {
          title: 'Dados de Produção',
          value: '0-1-0',
          key: '0-1-0',
          children: [
            {
              title: 'Dados de Produção - Visualização',
              value: '0-1-0-0',
              key: '0-1-0-0',
            },
          ],
        },
        {
          title: 'Testes de Poços',
          value: '0-1-1',
          key: '0-1-1',
          children: [
            {
              title: 'Testes de Poços - Visualização',
              value: '0-1-1-0',
              key: '0-1-1-0',
            },
          ],
        },
        {
          title: 'Notificação de Falhas',
          value: '0-1-2',
          key: '0-1-2',
          children: [
            {
              title: 'Notificação de Falhas - Visualização',
              value: '0-1-2-0',
              key: '0-1-2-0',
            },
          ],
        },
      ],
    },
    {
      title: 'Gestão de Dados Operacionais',
      value: '0-2',
      key: '0-2',
      children: [
        {
          title: 'Configuração de Parâmetros',
          value: '0-2-0',
          key: '0-2-0',
          children: [
            {
              title: 'Configuração de Parâmetros - Visualização',
              value: '0-2-0-0',
              key: '0-2-0-0',
            },
          ],
        },
        {
          title: 'Dados Operacionais',
          value: '0-2-1',
          key: '0-2-1',
          children: [
            {
              title: 'Dados Operacionais - Visualização',
              value: '0-2-1-0',
              key: '0-2-1-0',
            },
          ],
        },
        {
          title: 'Histórico de Dados do PI',
          value: '0-2-2',
          key: '0-2-2',
          children: [
            {
              title: 'Histórico de Dados do PI - Visualização',
              value: '0-2-2-0',
              key: '0-2-2-0',
            },
          ],
        },
      ],
    },
    {
      title: 'Eventos de Poço',
      value: '0-3',
      key: '0-3',
      children: [
        {
          title: 'Abertura de Poço',
          value: '0-3-0',
          key: '0-3-0',
          children: [
            {
              title: 'Abertura de Poço - Visualização',
              value: '0-3-0-0',
              key: '0-3-0-0',
            },
          ],
        },
        {
          title: 'Fechamento de Poço',
          value: '0-3-1',
          key: '0-3-1',
          children: [
            {
              title: 'Fechamento de Poço - Visualização',
              value: '0-3-1-0',
              key: '0-3-1-0',
            },
          ],
        },
        {
          title: 'Consultar Eventos do Poço',
          value: '0-3-2',
          key: '0-3-2',
          children: [
            {
              title: 'Consultar Eventos do Poço - Visualização',
              value: '0-3-2-0',
              key: '0-3-2-0',
            },
          ],
        },
      ],
    },
    {
      title: 'Geração de Arquivos',
      value: '0-4',
      key: '0-4',
      children: [
        {
          title: 'Arquivos XML',
          value: '0-4-0',
          key: '0-4-0',
          children: [
            {
              title: 'Arquivos XML - Visualização',
              value: '0-4-0-0',
              key: '0-4-0-0',
            },
          ],
        },
        {
          title: 'Relatórios',
          value: '0-4-1',
          key: '0-4-1',
          children: [
            {
              title: 'Relatórios - Visualização',
              value: '0-4-1-0',
              key: '0-4-1-0',
            },
          ],
        },
      ],
    },
  ];

  const treeSelectRequestBotafogo = async (arr, menuRes, operationsRes) => {
    const verbsRequest = {
      get_id: '',
      patch_id: '',
      post_id: '',
    };

    const administracao = {
      id: '',
      cadastros_Basicos_id: '',
      grupos_id: '',
      usuarios_id: '',
      configurar_Calculo_id: '',
      importar_dados_hierarquia_id: '',
    };

    const gestaoDadosProducao = {
      id: '',
      dados_Producao_id: '',
      testes_Pocos_id: '',
      notificacao_Falhas_id: '',
    };

    const gestaoDadosOperacionais = {
      id: '',
      configuracao_Parametros_id: '',
      dados_Operacionais_id: '',
      historico_Dados_Pi_id: '',
    };

    const eventosPoco = {
      id: '',
      abertura_id: '',
      fechamento_id: '',
      consulta_id: '',
    };

    const geracaoArquivos = {
      id: '',
      arquivos_XML_id: '',
      relatorios_id: '',
    };

    const dict = {
      '0-0': 'Administração',
      '0-0-0': 'Cadastro Básico',
      '0-0-1': 'Grupos',
      '0-0-2': 'Usuários',
      '0-0-3': 'Configurar Cálculos',
      '0-0-4': 'Importar Dados de Hierarquia',
      '0-0-0-0': 'Leitura_CadastroBásico',
      '0-0-1-0': 'Leitura_Grupos',
      '0-0-1-1': 'Atualização_Grupos',
      '0-0-1-2': 'Criação_Grupos',
      '0-0-2-0': 'Leitura_Usuários',
      '0-0-2-1': 'Atualização_Usuários',
      '0-0-2-2': 'Criação_Usuários',
      '0-0-3-0': 'Leitura_ConfigurarCálculos',
      '0-0-4-0': 'Leitura_ImportarDadosHierarquia',
      '0-1': 'Gestão de Dados de Produção',
      '0-1-0': 'Dados de Produção',
      '0-1-1': 'Testes de Poços',
      '0-1-2': 'Notificação de Falhas',
      '0-1-0-0': 'Leitura_DadosdeProdução',
      '0-1-1-0': 'Leitura_TestesdePoços',
      '0-1-2-0': 'Leitura_NotificaçãodeFalhas',
      '0-2': 'Gestão de Dados Operacionais',
      '0-2-0': 'Configuração de Parâmetros',
      '0-2-1': 'Dados Operacionais',
      '0-2-2': 'Histórico de Dados do PI',
      '0-2-0-0': 'Leitura_ConfiguraçãodeParâmetros',
      '0-2-1-0': 'Leitura_DadosOperacionais',
      '0-2-2-0': 'Leitura_HistóricodeDadosdoPI',
      '0-3': 'Eventos de Poço',
      '0-3-0': 'Abertura de Poço',
      '0-3-1': 'Fechamento de Poço',
      '0-3-0-0': 'Leitura_AberturadePoço',
      '0-3-1-0': 'Leitura_FechamentodePoço',
      '0-3-2': 'Consultar Eventos do Poço',
      '0-3-2-0': 'Leitura_ConsultarEventosdoPoço',
      '0-4': 'Geração de Arquivos',
      '0-4-0': 'Arquivos XML',
      '0-4-0-0': 'Leitura_ArquivosXML',
      '0-4-1': 'Relatórios',
      '0-4-1-0': 'Leitura_Relatórios',
    };

    try {
      // Menu Parents
      const administracaoMenus = menuRes.find(
        (menu) => menu.name === 'Administração',
      ).children;
      const gestaoDadosProducaoMenus = menuRes.find(
        (menu) => menu.name === 'Gestão de Dados de Produção',
      ).children;
      const gestaoDadosOperacionaisMenus = menuRes.find(
        (menu) => menu.name === 'Gestão de Dados Operacionais',
      ).children;
      const eventosPocoMenus = menuRes.find(
        (menu) => menu.name === 'Eventos de Poço',
      ).children;
      const geracaoArquivosMenus = menuRes.find(
        (menu) => menu.name === 'Geração de Arquivos',
      ).children;

      // Operations
      verbsRequest['get_id'] = operationsRes.find(
        (operation) => operation.method === 'GET',
      ).id;
      verbsRequest['post_id'] = operationsRes.find(
        (operation) => operation.method === 'POST',
      ).id;
      verbsRequest['patch_id'] = operationsRes.find(
        (operation) => operation.method === 'PATCH',
      ).id;

      // Administração
      administracao['id'] = menuRes.find(
        (menu) => menu.name === 'Administração',
      ).id;
      administracao['cadastros_Basicos_id'] = administracaoMenus.find(
        (menu) => menu.name === 'Cadastros Básicos',
      ).id;
      administracao['grupos_id'] = administracaoMenus.find(
        (menu) => menu.name === 'Grupos',
      ).id;
      administracao['usuarios_id'] = administracaoMenus.find(
        (menu) => menu.name === 'Usuários',
      ).id;
      administracao['configurar_Calculo_id'] = administracaoMenus.find(
        (menu) => menu.name === 'Configurar Cálculos',
      ).id;
      administracao['importar_dados_hierarquia_id'] = administracaoMenus.find(
        (menu) => menu.name === 'Importar Dados de Hierarquia',
      ).id;

      // Gestão de Dados de Produção
      gestaoDadosProducao['id'] = menuRes.find(
        (menu) => menu.name === 'Gestão de Dados de Produção',
      ).id;
      gestaoDadosProducao['dados_Producao_id'] = gestaoDadosProducaoMenus.find(
        (menu) => menu.name === 'Dados de Produção',
      ).id;
      gestaoDadosProducao['testes_Pocos_id'] = gestaoDadosProducaoMenus.find(
        (menu) => menu.name === 'Testes de Poços',
      ).id;
      gestaoDadosProducao['notificacao_Falhas_id'] =
        gestaoDadosProducaoMenus.find(
          (menu) => menu.name === 'Notificação de Falhas',
        ).id;

      // Gestão de Dados Operacionais
      gestaoDadosOperacionais['id'] = menuRes.find(
        (menu) => menu.name === 'Gestão de Dados Operacionais',
      ).id;
      gestaoDadosOperacionais['configuracao_Parametros_id'] =
        gestaoDadosOperacionaisMenus.find(
          (menu) => menu.name === 'Configuração de Parâmetros',
        ).id;
      gestaoDadosOperacionais['dados_Operacionais_id'] =
        gestaoDadosOperacionaisMenus.find(
          (menu) => menu.name === 'Dados Operacionais',
        ).id;
      gestaoDadosOperacionais['historico_Dados_Pi_id'] =
        gestaoDadosOperacionaisMenus.find(
          (menu) => menu.name === 'Histórico de Dados do PI',
        ).id;

      // Eventos de Poço
      eventosPoco['id'] = menuRes.find(
        (menu) => menu.name === 'Eventos de Poço',
      ).id;
      eventosPoco['abertura_id'] = eventosPocoMenus.find(
        (menu) => menu.name === 'Abertura de Poço',
      ).id;
      eventosPoco['fechamento_id'] = eventosPocoMenus.find(
        (menu) => menu.name === 'Fechamento de Poço',
      ).id;
      eventosPoco['consulta_id'] = eventosPocoMenus.find(
        (menu) => menu.name === 'Consultar Eventos do Poço',
      ).id;

      // Geração de Arquivos
      geracaoArquivos['id'] = menuRes.find(
        (menu) => menu.name === 'Geração de Arquivos',
      ).id;
      geracaoArquivos['arquivos_XML_id'] = geracaoArquivosMenus.find(
        (menu) => menu.name === 'Arquivos XML',
      ).id;
      geracaoArquivos['relatorios_id'] = geracaoArquivosMenus.find(
        (menu) => menu.name === 'Relatórios',
      ).id;
    } catch (error) {
      console.log(error);
    }

    const menus = [];

    arr.forEach((menu) => {
      const menuName = dict[menu];
      const dictKeyFormated = menu.replaceAll('-', '');

      if (dictKeyFormated.length === 2) {
        if (menuName === 'Administração') {
          const obj = {
            menuId: administracao.id,
            childrens: [
              {
                childrenId: administracao.cadastros_Basicos_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: administracao.grupos_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                  {
                    operationId: verbsRequest.patch_id,
                  },
                  {
                    operationId: verbsRequest.post_id,
                  },
                ],
              },
              {
                childrenId: administracao.usuarios_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                  {
                    operationId: verbsRequest.patch_id,
                  },
                  {
                    operationId: verbsRequest.post_id,
                  },
                ],
              },
              {
                childrenId: administracao.configurar_Calculo_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: administracao.importar_dados_hierarquia_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
            ],
          };

          menus.push(obj);
        }
        if (menuName === 'Gestão de Dados de Produção') {
          const obj = {
            menuId: gestaoDadosProducao.id,
            childrens: [
              {
                childrenId: gestaoDadosProducao.dados_Producao_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: gestaoDadosProducao.testes_Pocos_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: gestaoDadosProducao.notificacao_Falhas_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
            ],
          };

          menus.push(obj);
        }
        if (menuName === 'Gestão de Dados Operacionais') {
          const obj = {
            menuId: gestaoDadosOperacionais.id,
            childrens: [
              {
                childrenId: gestaoDadosOperacionais.configuracao_Parametros_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: gestaoDadosOperacionais.dados_Operacionais_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: gestaoDadosOperacionais.historico_Dados_Pi_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
            ],
          };

          menus.push(obj);
        }
        if (menuName === 'Eventos de Poço') {
          const obj = {
            menuId: eventosPoco.id,
            childrens: [
              {
                childrenId: eventosPoco.abertura_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: eventosPoco.fechamento_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: eventosPoco.consulta_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
            ],
          };

          menus.push(obj);
        }
        if (menuName === 'Geração de Arquivos') {
          const obj = {
            menuId: geracaoArquivos.id,
            childrens: [
              {
                childrenId: geracaoArquivos.arquivos_XML_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
              {
                childrenId: geracaoArquivos.relatorios_id,
                operations: [
                  {
                    operationId: verbsRequest.get_id,
                  },
                ],
              },
            ],
          };

          menus.push(obj);
        }
      } else if (dictKeyFormated.length === 3) {
        if (menuName === 'Cadastro Básico') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === administracao.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === administracao.id) {
                  menu.childrens.push({
                    childrenId: administracao.cadastros_Basicos_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: administracao.id,
                childrens: [
                  {
                    childrenId: administracao.cadastros_Basicos_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: administracao.id,
              childrens: [
                {
                  childrenId: administracao.cadastros_Basicos_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Grupos') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === administracao.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === administracao.id) {
                  menu.childrens.push({
                    childrenId: administracao.grupos_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                      {
                        operationId: verbsRequest.patch_id,
                      },
                      {
                        operationId: verbsRequest.post_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: administracao.id,
                childrens: [
                  {
                    childrenId: administracao.grupos_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                      {
                        operationId: verbsRequest.patch_id,
                      },
                      {
                        operationId: verbsRequest.post_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: administracao.id,
              childrens: [
                {
                  childrenId: administracao.grupos_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                    {
                      operationId: verbsRequest.patch_id,
                    },
                    {
                      operationId: verbsRequest.post_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Usuários') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === administracao.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === administracao.id) {
                  menu.childrens.push({
                    childrenId: administracao.usuarios_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                      {
                        operationId: verbsRequest.patch_id,
                      },
                      {
                        operationId: verbsRequest.post_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: administracao.id,
                childrens: [
                  {
                    childrenId: administracao.usuarios_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                      {
                        operationId: verbsRequest.patch_id,
                      },
                      {
                        operationId: verbsRequest.post_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: administracao.id,
              childrens: [
                {
                  childrenId: administracao.usuarios_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                    {
                      operationId: verbsRequest.patch_id,
                    },
                    {
                      operationId: verbsRequest.post_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Configurar Cálculos') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === administracao.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === administracao.id) {
                  menu.childrens.push({
                    childrenId: administracao.configurar_Calculo_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: administracao.id,
                childrens: [
                  {
                    childrenId: administracao.configurar_Calculo_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: administracao.id,
              childrens: [
                {
                  childrenId: administracao.configurar_Calculo_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Importar Dados de Hierarquia') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === administracao.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === administracao.id) {
                  menu.childrens.push({
                    childrenId: administracao.importar_dados_hierarquia_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: administracao.id,
                childrens: [
                  {
                    childrenId: administracao.importar_dados_hierarquia_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: administracao.id,
              childrens: [
                {
                  childrenId: administracao.importar_dados_hierarquia_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Dados de Produção') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === gestaoDadosProducao.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === gestaoDadosProducao.id) {
                  menu.childrens.push({
                    childrenId: gestaoDadosProducao.dados_Producao_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: gestaoDadosProducao.id,
                childrens: [
                  {
                    childrenId: gestaoDadosProducao.dados_Producao_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: gestaoDadosProducao.id,
              childrens: [
                {
                  childrenId: gestaoDadosProducao.dados_Producao_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Testes de Poços') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === gestaoDadosProducao.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === gestaoDadosProducao.id) {
                  menu.childrens.push({
                    childrenId: gestaoDadosProducao.testes_Pocos_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: gestaoDadosProducao.id,
                childrens: [
                  {
                    childrenId: gestaoDadosProducao.testes_Pocos_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: gestaoDadosProducao.id,
              childrens: [
                {
                  childrenId: gestaoDadosProducao.testes_Pocos_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Notificação de Falhas') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === gestaoDadosProducao.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === gestaoDadosProducao.id) {
                  menu.childrens.push({
                    childrenId: gestaoDadosProducao.notificacao_Falhas_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: gestaoDadosProducao.id,
                childrens: [
                  {
                    childrenId: gestaoDadosProducao.notificacao_Falhas_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: gestaoDadosProducao.id,
              childrens: [
                {
                  childrenId: gestaoDadosProducao.notificacao_Falhas_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Configuração de Parâmetros') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === gestaoDadosOperacionais.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === gestaoDadosOperacionais.id) {
                  menu.childrens.push({
                    childrenId:
                      gestaoDadosOperacionais.configuracao_Parametros_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: gestaoDadosOperacionais.id,
                childrens: [
                  {
                    childrenId:
                      gestaoDadosOperacionais.configuracao_Parametros_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: gestaoDadosOperacionais.id,
              childrens: [
                {
                  childrenId:
                    gestaoDadosOperacionais.configuracao_Parametros_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Dados Operacionais') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === gestaoDadosOperacionais.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === gestaoDadosOperacionais.id) {
                  menu.childrens.push({
                    childrenId: gestaoDadosOperacionais.dados_Operacionais_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: gestaoDadosOperacionais.id,
                childrens: [
                  {
                    childrenId: gestaoDadosOperacionais.dados_Operacionais_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: gestaoDadosOperacionais.id,
              childrens: [
                {
                  childrenId: gestaoDadosOperacionais.dados_Operacionais_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Histórico de Dados do PI') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === gestaoDadosOperacionais.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === gestaoDadosOperacionais.id) {
                  menu.childrens.push({
                    childrenId: gestaoDadosOperacionais.historico_Dados_Pi_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: gestaoDadosOperacionais.id,
                childrens: [
                  {
                    childrenId: gestaoDadosOperacionais.historico_Dados_Pi_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: gestaoDadosOperacionais.id,
              childrens: [
                {
                  childrenId: gestaoDadosOperacionais.historico_Dados_Pi_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Abertura de Poço') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === eventosPoco.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === eventosPoco.id) {
                  menu.childrens.push({
                    childrenId: eventosPoco.abertura_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: eventosPoco.id,
                childrens: [
                  {
                    childrenId: eventosPoco.abertura_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: eventosPoco.id,
              childrens: [
                {
                  childrenId: eventosPoco.abertura_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Fechamento de Poço') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === eventosPoco.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === eventosPoco.id) {
                  menu.childrens.push({
                    childrenId: eventosPoco.fechamento_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: eventosPoco.id,
                childrens: [
                  {
                    childrenId: eventosPoco.fechamento_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: eventosPoco.id,
              childrens: [
                {
                  childrenId: eventosPoco.fechamento_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Consultar Eventos do Poço') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === eventosPoco.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === eventosPoco.id) {
                  menu.childrens.push({
                    childrenId: eventosPoco.consulta_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: eventosPoco.id,
                childrens: [
                  {
                    childrenId: eventosPoco.consulta_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: eventosPoco.id,
              childrens: [
                {
                  childrenId: eventosPoco.consulta_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Arquivos XML') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === geracaoArquivos.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === geracaoArquivos.id) {
                  menu.childrens.push({
                    childrenId: geracaoArquivos.arquivos_XML_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: geracaoArquivos.id,
                childrens: [
                  {
                    childrenId: geracaoArquivos.arquivos_XML_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: geracaoArquivos.id,
              childrens: [
                {
                  childrenId: geracaoArquivos.relatorios_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        } else if (menuName === 'Relatórios') {
          if (menus.length > 0) {
            const parentMenu = menus.find(
              (menu) => menu.menuId === geracaoArquivos.id,
            );

            if (parentMenu) {
              menus.forEach((menu) => {
                if (menu.menuId === geracaoArquivos.id) {
                  menu.childrens.push({
                    childrenId: geracaoArquivos.relatorios_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  });
                }
              });
            }

            if (!parentMenu) {
              menus.push({
                menuId: geracaoArquivos.id,
                childrens: [
                  {
                    childrenId: geracaoArquivos.relatorios_id,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }

          if (menus.length === 0) {
            menus.push({
              menuId: geracaoArquivos.id,
              childrens: [
                {
                  childrenId: geracaoArquivos.relatorios_id,
                  operations: [
                    {
                      operationId: verbsRequest.get_id,
                    },
                  ],
                },
              ],
            });
          }
        }

        // --------------- CÓDIGO PARA ITEM SEM FILHO -----------------
        // const menuNumber = menu.replaceAll('-', '').split('')[1];
        // const subMenuNumero = menu.replaceAll('-', '').split('')[2];
        // const verboResquet = menu.replaceAll('-', '').split('')[3];

        // if (menuNumber === 4) {
        //   if (subMenuNumero === 0) {
        //     if (menus.length > 0) {
        //       const parentMenu = menus.find(
        //         (menu) => menu.menuId === geracaoArquivos.id,
        //       );

        //       if (parentMenu) {
        //         menus.forEach((menu) => {
        //           if (menu.menuId === geracaoArquivos.id) {
        //             menu.operations.push({
        //               operationId: verbsRequest.get_id,
        //             });
        //           }
        //         });
        //       }

        //       if (!parentMenu) {
        //         menus.push({
        //           menuId: geracaoArquivos.id,
        //           operations: [
        //             {
        //               operationId: verbsRequest.get_id,
        //             },
        //           ],
        //         });
        //       }
        //     }

        //     if (menus.length === 0) {
        //       menus.push({
        //         menuId: geracaoArquivos.id,
        //         operations: [
        //           {
        //             operationId: verbsRequest.get_id,
        //           },
        //         ],
        //       });
        //     }
        //   } else if (subMenuNumero === 1) {
        //     if (menus.length > 0) {
        //       const parentMenu = menus.find(
        //         (menu) => menu.menuId === geracaoArquivos.id,
        //       );

        //       if (parentMenu) {
        //         menus.forEach((menu) => {
        //           if (menu.menuId === geracaoArquivos.id) {
        //             menu.operations.push({
        //               operationId: verbsRequest.patch_id,
        //             });
        //           }
        //         });
        //       }

        //       if (!parentMenu) {
        //         menus.push({
        //           menuId: geracaoArquivos.id,
        //           operations: [
        //             {
        //               operationId: verbsRequest.patch_id,
        //             },
        //           ],
        //         });
        //       }
        //     }

        //     if (menus.length === 0) {
        //       menus.push({
        //         menuId: geracaoArquivos.id,
        //         operations: [
        //           {
        //             operationId: verbsRequest.patch_id,
        //           },
        //         ],
        //       });
        //     }
        //   } else if (subMenuNumero === 2) {
        //     if (menus.length > 0) {
        //       const parentMenu = menus.find(
        //         (menu) => menu.menuId === geracaoArquivos.id,
        //       );

        //       if (parentMenu) {
        //         menus.forEach((menu) => {
        //           if (menu.menuId === geracaoArquivos.id) {
        //             menu.operations.push({
        //               operationId: verbsRequest.post_id,
        //             });
        //           }
        //         });
        //       }

        //       if (!parentMenu) {
        //         menus.push({
        //           menuId: geracaoArquivos.id,
        //           operations: [
        //             {
        //               operationId: verbsRequest.post_id,
        //             },
        //           ],
        //         });
        //       }
        //     }

        //     if (menus.length === 0) {
        //       menus.push({
        //         menuId: geracaoArquivos.id,
        //         operations: [
        //           {
        //             operationId: verbsRequest.post_id,
        //           },
        //         ],
        //       });
        //     }
        //   }
        // }
        // ------------------------------------------------------------
      }

      if (dictKeyFormated.length === 4) {
        const menuNumber = menu.replaceAll('-', '').split('')[1];
        const subMenuNumero = menu.replaceAll('-', '').split('')[2];
        const verboResquet = menu.replaceAll('-', '').split('')[3];

        if (menuNumber === 0) {
          let idSubMenu = '';

          if (subMenuNumero === 0) {
            idSubMenu = administracao.cadastros_Basicos_id;
          }

          if (subMenuNumero === 1) {
            idSubMenu = administracao.grupos_id;
          }

          if (subMenuNumero === 2) {
            idSubMenu = administracao.usuarios_id;
          }

          if (subMenuNumero === 3) {
            idSubMenu = administracao.configurar_Calculo_id;
          }

          if (subMenuNumero === 4) {
            idSubMenu = administracao.importar_dados_hierarquia_id;
          }

          if (verboResquet === 0) {
            if (menus.length > 0) {
              const parentMenu = menus.find(
                (menu) => menu.menuId === administracao.id,
              );

              if (parentMenu) {
                const childrenMenu = parentMenu.childrens.find(
                  (children) => children.childrenId === idSubMenu,
                );

                if (childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === administracao.id) {
                      menu.childrens.forEach((children) => {
                        if (children.childrenId === idSubMenu) {
                          children.operations.push({
                            operationId: verbsRequest.get_id,
                          });
                        }
                      });
                    }
                  });
                }

                if (!childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === administracao.id) {
                      menu.childrens.push({
                        childrenId: idSubMenu,
                        operations: [
                          {
                            operationId: verbsRequest.get_id,
                          },
                        ],
                      });
                    }
                  });
                }
              }

              if (!parentMenu) {
                menus.push({
                  menuId: administracao.id,
                  childrens: [
                    {
                      childrenId: idSubMenu,
                      operations: [
                        {
                          operationId: verbsRequest.get_id,
                        },
                      ],
                    },
                  ],
                });
              }
            }

            if (menus.length === 0) {
              menus.push({
                menuId: administracao.id,
                childrens: [
                  {
                    childrenId: idSubMenu,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          } else if (verboResquet === 1) {
            if (menus.length > 0) {
              const parentMenu = menus.find(
                (menu) => menu.menuId === administracao.id,
              );

              if (parentMenu) {
                const childrenMenu = parentMenu.childrens.find(
                  (children) => children.childrenId === idSubMenu,
                );

                if (childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === administracao.id) {
                      menu.childrens.forEach((children) => {
                        if (children.childrenId === idSubMenu) {
                          children.operations.push({
                            operationId: verbsRequest.patch_id,
                          });
                        }
                      });
                    }
                  });
                }

                if (!childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === administracao.id) {
                      menu.childrens.push({
                        childrenId: idSubMenu,
                        operations: [
                          {
                            operationId: verbsRequest.patch_id,
                          },
                        ],
                      });
                    }
                  });
                }
              }

              if (!parentMenu) {
                menus.push({
                  menuId: administracao.id,
                  childrens: [
                    {
                      childrenId: idSubMenu,
                      operations: [
                        {
                          operationId: verbsRequest.patch_id,
                        },
                      ],
                    },
                  ],
                });
              }
            }

            if (menus.length === 0) {
              menus.push({
                menuId: administracao.id,
                childrens: [
                  {
                    childrenId: idSubMenu,
                    operations: [
                      {
                        operationId: verbsRequest.patch_id,
                      },
                    ],
                  },
                ],
              });
            }
          } else if (verboResquet === 2) {
            if (menus.length > 0) {
              const parentMenu = menus.find(
                (menu) => menu.menuId === administracao.id,
              );

              if (parentMenu) {
                const childrenMenu = parentMenu.childrens.find(
                  (children) => children.childrenId === idSubMenu,
                );

                if (childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === administracao.id) {
                      menu.childrens.forEach((children) => {
                        if (children.childrenId === idSubMenu) {
                          children.operations.push({
                            operationId: verbsRequest.post_id,
                          });
                        }
                      });
                    }
                  });
                }

                if (!childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === administracao.id) {
                      menu.childrens.push({
                        childrenId: idSubMenu,
                        operations: [
                          {
                            operationId: verbsRequest.post_id,
                          },
                        ],
                      });
                    }
                  });
                }
              }

              if (!parentMenu) {
                menus.push({
                  menuId: administracao.id,
                  childrens: [
                    {
                      childrenId: idSubMenu,
                      operations: [
                        {
                          operationId: verbsRequest.post_id,
                        },
                      ],
                    },
                  ],
                });
              }
            }

            if (menus.length === 0) {
              menus.push({
                menuId: administracao.id,
                childrens: [
                  {
                    childrenId: idSubMenu,
                    operations: [
                      {
                        operationId: verbsRequest.post_id,
                      },
                    ],
                  },
                ],
              });
            }
          }
        }

        if (menuNumber === 1) {
          let idSubMenu = '';

          if (subMenuNumero === 0) {
            idSubMenu = gestaoDadosProducao.dados_Producao_id;
          }

          if (subMenuNumero === 1) {
            idSubMenu = gestaoDadosProducao.testes_Pocos_id;
          }

          if (subMenuNumero === 2) {
            idSubMenu = gestaoDadosProducao.notificacao_Falhas_id;
          }

          if (verboResquet === 0) {
            if (menus.length > 0) {
              const parentMenu = menus.find(
                (menu) => menu.menuId === gestaoDadosProducao.id,
              );

              if (parentMenu) {
                const childrenMenu = parentMenu.childrens.find(
                  (children) => children.childrenId === idSubMenu,
                );

                if (childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === gestaoDadosProducao.id) {
                      menu.childrens.forEach((children) => {
                        if (children.childrenId === idSubMenu) {
                          children.operations.push({
                            operationId: verbsRequest.get_id,
                          });
                        }
                      });
                    }
                  });
                }

                if (!childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === gestaoDadosProducao.id) {
                      menu.childrens.push({
                        childrenId: idSubMenu,
                        operations: [
                          {
                            operationId: verbsRequest.get_id,
                          },
                        ],
                      });
                    }
                  });
                }
              }

              if (!parentMenu) {
                menus.push({
                  menuId: gestaoDadosProducao.id,
                  childrens: [
                    {
                      childrenId: idSubMenu,
                      operations: [
                        {
                          operationId: verbsRequest.get_id,
                        },
                      ],
                    },
                  ],
                });
              }
            }

            if (menus.length === 0) {
              menus.push({
                menuId: gestaoDadosProducao.id,
                childrens: [
                  {
                    childrenId: idSubMenu,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }
        }

        if (menuNumber === 2) {
          let idSubMenu = '';

          if (subMenuNumero === 0) {
            idSubMenu = gestaoDadosOperacionais.configuracao_Parametros_id;
          }

          if (subMenuNumero === 1) {
            idSubMenu = gestaoDadosOperacionais.dados_Operacionais_id;
          }

          if (subMenuNumero === 2) {
            idSubMenu = gestaoDadosOperacionais.historico_Dados_Pi_id;
          }

          if (verboResquet === 0) {
            if (menus.length > 0) {
              const parentMenu = menus.find(
                (menu) => menu.menuId === gestaoDadosOperacionais.id,
              );

              if (parentMenu) {
                const childrenMenu = parentMenu.childrens.find(
                  (children) => children.childrenId === idSubMenu,
                );

                if (childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === gestaoDadosOperacionais.id) {
                      menu.childrens.forEach((children) => {
                        if (children.childrenId === idSubMenu) {
                          children.operations.push({
                            operationId: verbsRequest.get_id,
                          });
                        }
                      });
                    }
                  });
                }

                if (!childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === gestaoDadosOperacionais.id) {
                      menu.childrens.push({
                        childrenId: idSubMenu,
                        operations: [
                          {
                            operationId: verbsRequest.get_id,
                          },
                        ],
                      });
                    }
                  });
                }
              }

              if (!parentMenu) {
                menus.push({
                  menuId: gestaoDadosOperacionais.id,
                  childrens: [
                    {
                      childrenId: idSubMenu,
                      operations: [
                        {
                          operationId: verbsRequest.get_id,
                        },
                      ],
                    },
                  ],
                });
              }
            }

            if (menus.length === 0) {
              menus.push({
                menuId: gestaoDadosOperacionais.id,
                childrens: [
                  {
                    childrenId: idSubMenu,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }
        }

        if (menuNumber === 3) {
          let idSubMenu = '';

          if (subMenuNumero === 0) {
            idSubMenu = eventosPoco.abertura_id;
          }

          if (subMenuNumero === 1) {
            idSubMenu = eventosPoco.fechamento_id;
          }

          if (subMenuNumero === 2) {
            idSubMenu = eventosPoco.consulta_id;
          }

          if (verboResquet === 0) {
            if (menus.length > 0) {
              const parentMenu = menus.find(
                (menu) => menu.menuId === eventosPoco.id,
              );

              if (parentMenu) {
                const childrenMenu = parentMenu.childrens.find(
                  (children) => children.childrenId === idSubMenu,
                );

                if (childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === eventosPoco.id) {
                      menu.childrens.forEach((children) => {
                        if (children.childrenId === idSubMenu) {
                          children.operations.push({
                            operationId: verbsRequest.get_id,
                          });
                        }
                      });
                    }
                  });
                }

                if (!childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === eventosPoco.id) {
                      menu.childrens.push({
                        childrenId: idSubMenu,
                        operations: [
                          {
                            operationId: verbsRequest.get_id,
                          },
                        ],
                      });
                    }
                  });
                }
              }

              if (!parentMenu) {
                menus.push({
                  menuId: eventosPoco.id,
                  childrens: [
                    {
                      childrenId: idSubMenu,
                      operations: [
                        {
                          operationId: verbsRequest.get_id,
                        },
                      ],
                    },
                  ],
                });
              }
            }

            if (menus.length === 0) {
              menus.push({
                menuId: eventosPoco.id,
                childrens: [
                  {
                    childrenId: idSubMenu,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }
        }

        if (menuNumber === 4) {
          let idSubMenu = '';

          if (subMenuNumero === 0) {
            idSubMenu = geracaoArquivos.arquivos_XML_id;
          }

          if (subMenuNumero === 1) {
            idSubMenu = geracaoArquivos.relatorios_id;
          }

          if (verboResquet === 0) {
            if (menus.length > 0) {
              const parentMenu = menus.find(
                (menu) => menu.menuId === geracaoArquivos.id,
              );

              if (parentMenu) {
                const childrenMenu = parentMenu.childrens.find(
                  (children) => children.childrenId === idSubMenu,
                );

                if (childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === geracaoArquivos.id) {
                      menu.childrens.forEach((children) => {
                        if (children.childrenId === idSubMenu) {
                          children.operations.push({
                            operationId: verbsRequest.get_id,
                          });
                        }
                      });
                    }
                  });
                }

                if (!childrenMenu) {
                  menus.forEach((menu) => {
                    if (menu.menuId === geracaoArquivos.id) {
                      menu.childrens.push({
                        childrenId: idSubMenu,
                        operations: [
                          {
                            operationId: verbsRequest.get_id,
                          },
                        ],
                      });
                    }
                  });
                }
              }

              if (!parentMenu) {
                menus.push({
                  menuId: geracaoArquivos.id,
                  childrens: [
                    {
                      childrenId: idSubMenu,
                      operations: [
                        {
                          operationId: verbsRequest.get_id,
                        },
                      ],
                    },
                  ],
                });
              }
            }

            if (menus.length === 0) {
              menus.push({
                menuId: geracaoArquivos.id,
                childrens: [
                  {
                    childrenId: idSubMenu,
                    operations: [
                      {
                        operationId: verbsRequest.get_id,
                      },
                    ],
                  },
                ],
              });
            }
          }
        }
      }
    });

    return menus;
  };

  const getMenuAccessBotafogo = (data) => {
    const permissions = [];

    data.groupPermissions.forEach((menu) => {
      const parentTreeData = treeData.find(
        (data) => data.title === menu.menuName,
      );

      menu.hasChildren &&
        menu.children.forEach((children) => {
          const childrenTreeData = parentTreeData.children.find(
            (data) => data.title === children.menuName,
          );

          children.operations.forEach((operation) => {
            if (operation && operation.operationName === 'GET') {
              permissions.push(`${childrenTreeData.value}-0`);
            }

            if (operation && operation.operationName === 'PATCH') {
              permissions.push(`${childrenTreeData.value}-1`);
            }

            if (operation && operation.operationName === 'POST') {
              permissions.push(`${childrenTreeData.value}-2`);
            }
          });
        });

      !menu.hasChildren &&
        menu.operations.forEach((operation) => {
          if (operation && operation.operationName === 'GET') {
            permissions.push(`${parentTreeData.value}-0`);
          }

          if (operation && operation.operationName === 'PATCH') {
            permissions.push(`${parentTreeData.value}-1`);
          }

          if (operation && operation.operationName === 'POST') {
            permissions.push(`${parentTreeData.value}-2`);
          }
        });
    });

    return permissions;
  };

  const getUserMenuAccessBotafogo = (data) => {
    const permissions = [];

    data.userPermissions.forEach((menu) => {
      const parentTreeData = treeData.find(
        (data) => data.title === menu.menuName,
      );

      menu.hasChildren &&
        menu.children.forEach((children) => {
          const childrenTreeData = parentTreeData.children.find(
            (data) => data.title === children.menuName,
          );

          children.userOperation.forEach((operation) => {
            if (operation && operation.operationName === 'GET') {
              permissions.push(`${childrenTreeData.value}-0`);
            }

            if (operation && operation.operationName === 'PATCH') {
              permissions.push(`${childrenTreeData.value}-1`);
            }

            if (operation && operation.operationName === 'POST') {
              permissions.push(`${childrenTreeData.value}-2`);
            }
          });
        });

      !menu.hasChildren &&
        menu.userOperation.forEach((operation) => {
          if (operation && operation.operationName === 'GET') {
            permissions.push(`${parentTreeData.value}-0`);
          }

          if (operation && operation.operationName === 'PATCH') {
            permissions.push(`${parentTreeData.value}-1`);
          }

          if (operation && operation.operationName === 'POST') {
            permissions.push(`${parentTreeData.value}-2`);
          }
        });
    });

    return permissions;
  };

  return (
    <createContextCadastroBotafogo.Provider
      value={{
        treeData,
        treeSelectDataBotafogo,
        setTreeSelectDataBotafogo,
        isStatusChanged,
        setIsStatusChanged,
        statusChanged,
        setStatusChanged,
        getMenuAccessBotafogo,
        getUserMenuAccessBotafogo,
        treeSelectRequestBotafogo,
      }}
    >
      {children}
    </createContextCadastroBotafogo.Provider>
  );
};

export default BotafogoCadastrosContext;
