import AppLocale from '@iso/config/translation';
import { ConfigProvider } from 'antd';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

export default function AppProvider({ children }) {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);

  const currentAppLocale = AppLocale[locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        {children}
      </IntlProvider>
    </ConfigProvider>
  );
}
