import React, { createContext, useState } from 'react'

export const instanceContext = createContext({})

const Instance = ({children}) => {
    const [selectedInstance, setSelectedInstance] = useState("")
    const [breadcrumbFieldId, setBreadcrumbFieldId] = useState("")

    return (
        <instanceContext.Provider value={{
            selectedInstance, setSelectedInstance,
            breadcrumbFieldId, setBreadcrumbFieldId
        }}> 
            {children}
        </instanceContext.Provider>
    )
}

export default Instance