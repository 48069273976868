import api from '@iso/containers/services/API/api';
import { message } from 'antd';
import React, { createContext, useState } from 'react';
import MomentDateConverte from '../components/utility/momentDateConverte';
import ApiBuild from '../containers/services/API/apiBuild';

export const wellTestContextObj = createContext({});

const WellTestContext = ({ children }) => {
  // const api = ApiBuild();

  const [loadingTable, setLoadingTable] = useState(true);

  const GetAllWellTest = async (
    isBotafogoInstance,
    wellId,
    selectedInstance,
  ) => {
    // const api = ApiBuild();
    try {
      setLoadingTable(true);

      const { data } = await api.get(
        isBotafogoInstance
          ? `btp/data/well/${wellId}?instance=${selectedInstance}`
          : `btp/data/well/${wellId}`,
      );

      const dataWithKey = data.map((d) => {
        return {
          ...d,
          key: d.id,
          finalDate: MomentDateConverte(d.finalDate, true),
          applicationDate: MomentDateConverte(d.applicationDate),
          isValid: d.isValid ? 'Válido' : 'Inválido',
          isApplicable: d.isActive ? 'Aplicável' : 'Não Aplicável',
        };
      });

      return dataWithKey;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTable(false);
    }
  };

  const SaveInvalid = async (id) => {
    try {
      await api.patch(`/btp/data/${id}`);
      message.success('Teste de Poço salvo.', 10);
    } catch (error) {
      message.error(error?.data?.message, 4);
      console.log(error);
    }
  };

  return (
    <wellTestContextObj.Provider
      value={{
        GetAllWellTest,
        loadingTable,
        setLoadingTable,
        SaveInvalid,
      }}
    >
      {children}
    </wellTestContextObj.Provider>
  );
};

export default WellTestContext;
