import React from 'react';
import { createContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

export const TableTypeObjContext = createContext({});

const TableTypes = ({ children }) => {
  const [totalGasBurned, setTotalGasBurned] = useState(0);
  const [typeInputEditable, setTypeInputEditable] = useState('');
  const [dataSourceGas, setDataSourceGas] = useState([]);
  const [dataSourceGasBackup, setDataSourceGasBackup] = useState([]);

  const initialDetailBurnedValues = (valuesObj) => {
    if (valuesObj) {
      setDataSourceGas([
        {
          key: uuid(),
          description: 'Volume de Queima por Limitação Operacional',
          value: parseFloat(valuesObj.limitOperacionalBurn).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Queima Durante Parada Programada',
          value: parseFloat(valuesObj.scheduledStopBurn).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Gás Ventilado',
          value: parseFloat(valuesObj.ventedGas).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Queima para Comissionamento',
          value: parseFloat(valuesObj.forCommissioningBurn).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Queima para Teste de Poço',
          value: parseFloat(valuesObj.wellTestBurn).toFixed(5),
        },
        {
          key: uuid,
          description: 'Volume de Queima Emergêncial',
          value: parseFloat(valuesObj.emergencialBurn).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Queima - Outros',
          value: parseFloat(valuesObj.othersBurn).toFixed(5),
        },
      ]);
    } else if (!valuesObj) {
      setDataSourceGas([
        {
          key: uuid(),
          description: 'Volume de Queima por Limitação Operacional',
          value: parseFloat(0).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Queima Durante Parada Programada',
          value: parseFloat(0).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Gás Ventilado',
          value: parseFloat(0).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Queima para Comissionamento',
          value: parseFloat(0).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Queima para Teste de Poço',
          value: parseFloat(0).toFixed(5),
        },
        {
          key: uuid,
          description: 'Volume de Queima Emergêncial',
          value: parseFloat(0).toFixed(5),
        },
        {
          key: uuid(),
          description: 'Volume de Queima - Outros',
          editable: false,
          value: parseFloat(totalGasBurned).toFixed(5),
        },
      ]);
    }
  };

  const updateBurnedValues = () => {
    setDataSourceGasBackup(dataSourceGas);
  };

  const restorBurnedDetail = () => {
    if (dataSourceGasBackup.length > 0) {
      setDataSourceGas(dataSourceGasBackup);
    } else {
      initialDetailBurnedValues();
    }
  };

  return (
    <TableTypeObjContext.Provider
      value={{
        typeInputEditable,
        setTypeInputEditable,
        dataSourceGas,
        setDataSourceGas,
        dataSourceGasBackup,
        setDataSourceGasBackup,
        initialDetailBurnedValues,
        updateBurnedValues,
        restorBurnedDetail,
        totalGasBurned,
        setTotalGasBurned,
      }}
    >
      {children}
    </TableTypeObjContext.Provider>
  );
};

export default TableTypes;
