import { createContext, useState } from "react";
import React from "react"

export const createContextCalculations = createContext({})

const CalculationsContext = ({children}) => {

    const [isActiveTramoA, setIsActiveTramoA] = useState(false)
    const [isActiveTramoB, setIsActiveTramoB] = useState(false)
    const [isActiveTramoC, setIsActiveTramoC] = useState(false)
    const [isActiveTramoD, setIsActiveTramoD] = useState(false)
    const [isActiveTog, setIsActiveTog] = useState(false)
    const [isActiveDreno, setIsActiveDreno] = useState(false)
    const [isActiveDorA, setIsActiveDorA] = useState(false)
    const [isActiveDorB, setIsActiveDorB] = useState(false)

    const onIsActiveTramoAChange = (e) => {
        const value = e.target.value

        setIsActiveTramoA(value)
    }

    const onIsActiveTramoBChange = (e) => {
        const value = e.target.value

        setIsActiveTramoB(value)
    }

    const onIsActiveTramoCChange = (e) => {
        const value = e.target.value

        setIsActiveTramoC(value)
    }

    const onIsActiveTramoDChange = (e) => {
        const value = e.target.value

        setIsActiveTramoD(value)
    }

    const onIsActiveTogChange = (e) => {
        const value = e.target.value

        setIsActiveTog(value)
    }

    const onIsActiveDrenoChange = (e) => {
        const value = e.target.value

        setIsActiveDreno(value)
    }

    const onIsActiveDorAChange = (e) => {
        const value = e.target.value

        setIsActiveDorA(value)
    }

    const onIsActiveDorBChange = (e) => {
        const value = e.target.value

        setIsActiveDorB(value)
    }

    const [isActiveHpFlare, setIsActiveHpFlare] = useState(false)
    const [isActiveLpFlare, setIsActiveLpFlare] = useState(false)
    const [isActiveHighPressureGas, setIsActiveHighPressureGas] = useState(false)
    const [isActiveLowPressureGas, setIsActiveLowPressureGas] = useState(false)
    const [isActiveExportGas1, setIsActiveExportGas1] = useState(false)
    const [isActiveExportGas2, setIsActiveExportGas2] = useState(false)
    const [isActiveExportGas3, setIsActiveExportGas3] = useState(false)
    const [isActiveImportGas1, setIsActiveImportGas1] = useState(false)
    const [isActiveImportGas2, setIsActiveImportGas2] = useState(false)
    const [isActiveImportGas3, setIsActiveImportGas3] = useState(false)
    const [isActiveAssistanceGas, setIsActiveAssistanceGas] = useState(false)
    const [isActivePilotGas, setIsActivePilotGas] = useState(false)
    const [isActivePurgeGas, setIsActivePurgeGas] = useState(false)

    const onIsActiveHpFlareChange = (e) => {
        const value = e.target.value

        setIsActiveHpFlare(value)
    }

    const onIsActiveLpFlareChange = (e) => {
        const value = e.target.value

        setIsActiveLpFlare(value)
    }

    const onIsActiveHighPressureGasChange = (e) => {
        const value = e.target.value

        setIsActiveHighPressureGas(value)
    }

    const onIsActiveLowPressureGasChange = (e) => {
        const value = e.target.value

        setIsActiveLowPressureGas(value)
    }

    const onIsActiveExportGas1Change = (e) => {
        const value = e.target.value

        setIsActiveExportGas1(value)
    }

    const onIsActiveExportGas2Change = (e) => {
        const value = e.target.value

        setIsActiveExportGas2(value)
    }

    const onIsActiveExportGas3Change = (e) => {
        const value = e.target.value

        setIsActiveExportGas3(value)
    }

    const onIsActiveImportGas1Change = (e) => {
        const value = e.target.value

        setIsActiveImportGas1(value)
    }

    const onIsActiveImportGas2Change = (e) => {
        const value = e.target.value

        setIsActiveImportGas2(value)
    }

    const onIsActiveImportGas3Change = (e) => {
        const value = e.target.value

        setIsActiveImportGas3(value)
    }

    const onIsActiveAssistanceGasChange = (e) => {
        const value = e.target.value

        setIsActiveAssistanceGas(value)
    }

    const onIsActivePilotGasChange = (e) => {
        const value = e.target.value

        setIsActivePilotGas(value)
    }

    const onIsActivePurgeGasChange = (e) => {
        const value = e.target.value

        setIsActivePurgeGas(value)
    }

    return (
        <createContextCalculations.Provider value={{
            isActiveTramoA, setIsActiveTramoA, onIsActiveTramoAChange,
            isActiveTramoB, setIsActiveTramoB, onIsActiveTramoBChange,
            isActiveTramoC, setIsActiveTramoC, onIsActiveTramoCChange,
            isActiveTramoD, setIsActiveTramoD, onIsActiveTramoDChange,
            isActiveTog, setIsActiveTog, onIsActiveTogChange,
            isActiveDreno, setIsActiveDreno, onIsActiveDrenoChange,
            isActiveDorA, setIsActiveDorA, onIsActiveDorAChange,
            isActiveDorB, setIsActiveDorB, onIsActiveDorBChange,

            isActiveHpFlare, setIsActiveHpFlare, onIsActiveHpFlareChange,
            isActiveLpFlare, setIsActiveLpFlare, onIsActiveLpFlareChange,
            isActiveHighPressureGas, setIsActiveHighPressureGas, onIsActiveHighPressureGasChange,
            isActiveLowPressureGas, setIsActiveLowPressureGas, onIsActiveLowPressureGasChange,
            isActiveExportGas1, setIsActiveExportGas1, onIsActiveExportGas1Change,
            isActiveExportGas2, setIsActiveExportGas2, onIsActiveExportGas2Change,
            isActiveExportGas3, setIsActiveExportGas3, onIsActiveExportGas3Change,
            isActiveImportGas1, setIsActiveImportGas1, onIsActiveImportGas1Change,
            isActiveImportGas2, setIsActiveImportGas2, onIsActiveImportGas2Change,
            isActiveImportGas3, setIsActiveImportGas3, onIsActiveImportGas3Change,
            isActiveAssistanceGas, setIsActiveAssistanceGas, onIsActiveAssistanceGasChange,
            isActivePilotGas, setIsActivePilotGas, onIsActivePilotGasChange,
            isActivePurgeGas, setIsActivePurgeGas, onIsActivePurgeGasChange
        }}>
            {children}
        </createContextCalculations.Provider>
    )

}

export default CalculationsContext