import { Map } from 'immutable';

export function clearToken() {
  localStorage.removeItem('id_token');
}

export function clearUser() {
  localStorage.removeItem('user');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getUser() {
  try {
    const user = localStorage.getItem('user');
    return user;
  } catch (err) {
    clearUser();
    return {};
  }
}
