import React, { useState } from "react"
import { createContext } from "react"

export const contextObjModal = createContext({})

const ModalState = ({children}) => {

    const [resumeOpenGas, setResumeOpenGas] = useState(false)
    const [tableInputOpenGas, setTableInputOpenGas] = useState(false)

    const [resumeOpenOil, setResumeOpenOil] = useState(false)
    const [tableInputOpenOil, setTableInputOpenOil] = useState(false)

    return (
        <contextObjModal.Provider value={{
            resumeOpenGas, 
            setResumeOpenGas,
            resumeOpenOil, 
            setResumeOpenOil,
            tableInputOpenGas, 
            setTableInputOpenGas,
            tableInputOpenOil, 
            setTableInputOpenOil
        }}>
            {children}
        </contextObjModal.Provider>
    )

}

export default ModalState