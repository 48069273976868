import api from '@iso/containers/services/API/api';
import React, { createContext } from 'react';
import ApiBuild from '../containers/services/API/apiBuild';

export const getRequestsContextObj = createContext({});

const GetsRequestContext = ({ children }) => {
  // const api = ApiBuild();

  const GetAllUsers = async (isBotafogoInstance, instance) => {
    try {
      const { data } = await api.get(
        isBotafogoInstance ? `users?instance=${instance}` : `users/`,
      );

      const arr = data.map((l) => {
        return { key: l.id, ...l };
      });

      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllInstallations = async (isBotafogoInstance, instance) => {
    try {
      const { data } = await api.get(
        isBotafogoInstance
          ? `installations?instance=${instance}`
          : `installations/`,
      );

      const arr = data.map((l) => {
        return { key: l.id, ...l };
      });

      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllFields = async (isBotafogoInstance, instance) => {
    try {
      const { data } = await api.get(
        isBotafogoInstance ? `fields?instance=${instance}` : `fields/`,
      );

      const arr = data.map((l) => {
        return { key: l.id, ...l };
      });

      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllWells = async (isBotafogoInstance, instance) => {
    try {
      const { data } = await api.get(
        isBotafogoInstance ? `wells?instance=${instance}` : `wells`,
      );

      const arr = data.map((l) => {
        return { key: l.id, ...l };
      });

      return arr;
    } catch (error) {
      console.log(error);
    }
  };

  const GetWellById = async (isBotafogoInstance, instance, wellId) => {
    try {
      const { data } = await api.get(
        isBotafogoInstance
          ? `wells/${wellId}?instance=${instance}`
          : `wells/${wellId}`,
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const GetBTPbyId = async (isBotafogoInstance, instance, btpId) => {
    try {
      const { data } = await api.get(
        isBotafogoInstance
          ? `btp/data/${btpId}?instance=${instance}`
          : `btp/data/${btpId}`,
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAllUEP = async (isBotafogoInstance, instance) => {
    try {
      const { data } = await api.get(
        isBotafogoInstance
          ? `installations/ueps?instance=${instance}`
          : `installations/ueps`,
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <getRequestsContextObj.Provider
      value={{
        GetAllUsers,
        GetAllFields,
        GetAllWells,
        GetWellById,
        GetBTPbyId,
        GetAllInstallations,
        getAllUEP,
      }}
    >
      {children}
    </getRequestsContextObj.Provider>
  );
};

export default GetsRequestContext;
