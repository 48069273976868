import React, { createContext, useState } from 'react';

export const permissionsContext = createContext({});

const Permissions = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [getPermission, setGetPermission] = useState(false);
  const [postPermission, setPostPermission] = useState(false);
  const [patchPermission, setPatchPermission] = useState(false);

  const checkPermissions = async (location, history) => {
    const instanceName = location.pathname.split('/')[2];
    const moduleName = `/${location.pathname.split('/')[3]}`;

    let menuItem = {};

    if (permissions.length > 0) {
      permissions.forEach((menuParent) => {
        if (menuParent.hasChildren) {
          menuItem =
            menuParent.children.find(
              (menuChildren) => menuChildren.menuRoute === moduleName,
            ) || menuItem;
        }

        if (!menuParent.hasChildren) {
          menuItem =
            permissions.find(
              (menuParent) => menuParent.menuRoute === moduleName,
            ) || menuItem;
        }

        if (moduleName === '/productionCad' && menuParent.hasChildren) {
          menuItem =
            menuParent.children.find(
              (menuChildren) =>
                menuChildren.menuRoute === '/importarDadosProducao',
            ) || menuItem;
        }
      });

      if (menuItem) {
        const getOperation = menuItem?.userOperation?.find(
          (operation) => operation.operationName === 'GET',
        );
        const postOperation = menuItem?.userOperation?.find(
          (operation) => operation.operationName === 'POST',
        );
        const patchOperation = menuItem?.userOperation?.find(
          (operation) => operation.operationName === 'PATCH',
        );

        if (getOperation) {
          setGetPermission(true);
        } else {
          history.replace(`/dashboard/${instanceName}`);
        }

        if (postOperation) {
          setPostPermission(true);
        }

        if (patchOperation) {
          setPatchPermission(true);
        }
      } else {
        history.replace(`/dashboard/${instanceName}`);
      }
    }
  };

  return (
    <permissionsContext.Provider
      value={{
        permissions,
        setPermissions,
        getPermission,
        postPermission,
        patchPermission,
        checkPermissions,
      }}
    >
      {children}
    </permissionsContext.Provider>
  );
};

export default Permissions;
