import language from '@iso/config/language.config';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'brasil',
      locale: 'pt',
      text: 'Português',
      icon: '',
    },

    {
      languageId: 'english',
      locale: 'en',
      text: 'English',
      icon: '',
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
