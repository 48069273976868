import dayjs from 'dayjs';

const MomentDateConverte = (string, dateTime) => {
  const dateParts = string.split('/');
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[2], 10);

  const onlyDate = new Date(year, month, day);

  let date;

  if (dateTime) {
    const hh = string.split('/')[2].split(' ')[1].split(':')[0];
    const mm = string.split('/')[2].split(' ')[1].split(':')[1];

    const dateAndTime = new Date(year, month, day, hh, mm);

    date = dayjs(dateAndTime).format('DD/MMM/YYYY HH:MM');
  } else {
    date = dayjs(onlyDate).format('DD/MMM/YYYY');
  }

  return date;
};

export default MomentDateConverte;
